
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import { Reservation } from '@/models/dto'
@Component({
  components: {},
})
export default class ChangeDueDateSidebar extends Vue {
  @Prop({ default: undefined, required: false })
  readonly bulkReservations!: Reservation[]

  displayedReservations = []
  loading = false

  @Watch('bulkReservations', { immediate: true })
  onBulkReservationChange(reservations: Reservation[]): void {
    this.displayedReservations = reservations
  }

  handleRemoveBulkReservation(reservationId: number): void {
    this.displayedReservations = this.displayedReservations.filter(
      (res) => res.reservationId !== reservationId
    )
    if (this.displayedReservations.length === 0) {
      sidebar.pop()
    }
  }

  cancel(): void {
    sidebar.pop()
  }

  submit(): void {
    sidebar.close()
  }
}
