
import { Component, Vue } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import { Status } from '@/models/dto/Status'
import { reservationStatusTypes } from '@/data/types'

@Component
export default class SetReservationStatusSidebar extends Vue {
  status: Status = null
  statusOptions = reservationStatusTypes

  setStatus(statusKey: string): void {
    this.status = this.statusOptions.find((status) => {
      return statusKey === status.key
    })
  }

  close(): void {
    sidebar.close()
  }

  submit(): void {
    this.$emit('set:status', this.status)
    this.close()
  }
}
