
import { Reservation } from '@/models/dto/Reservation'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/string'

@Component({
  components: {},
})
export default class ReservationsListTotalDue extends Vue {
  currencyFilter = currencyFilter
  @Prop({
    required: true,
  })
  row: Reservation

  get paymentStatusColor(): string {
    if (this.row.paymentStatus) {
      switch (this.row.paymentStatus) {
        case 'fully_paid':
          return 'green'
        case 'partially_paid':
          return 'yellow'
      }
    }
    return 'rust'
  }
}
