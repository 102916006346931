
import { Provide } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { DataTableColumn } from '@/models/DataTableColumn'
import reservations from '@/services/reservation'
import CUCollectionTable from '@/components/CUCollectionTable.vue'
import { TableAction } from '@/models/TableAction'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { Reservation, ReservationStatusUpdate } from '@/models/dto/Reservation'
import CompanySidebarDetail from './CompanySidebarDetail.vue'
import { SavedView } from '@/models/dto/SavedView'
import Placeholder from '@/views/Placeholder.vue'
import CreateInvoiceSidebar from '@/components/CreateInvoiceSidebar.vue'
import SetReservationStatusSidebar from '@/components/SetReservationStatusSidebar.vue'
import { initialSavedViews, columns } from '@/data/reservationTableView'
import ContactSidebarDetail from './ContactSidebarDetail.vue'
import ReservationProcessPaymentSidebar from '@/components/ReservationProcessPaymentSidebar.vue'
import HoldUpModal from './HoldUpModal.vue'
import { Status } from '@/models/dto/Status'
import LogPaymentSidebar from './LogPaymentSidebar.vue'
import AddRefundSidebar from '@/components/AddRefundSidebar.vue'
import ChangeDueDateSidebar from '@/components/ChangeDueDateSidebar.vue'
import { pluralize } from '@/utils/string'
import { SourceCategory } from '@/utils/enum'
import auth from '@/store/modules/auth'
import ReservationDetailPanel from './ReservationDetailPanel.vue'
import reservation from '@/store/modules/reservation'
import column from '@/store/modules/columns'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import app from '@/store/modules/app'
import assignment from '@/store/modules/assignment'
import AssignmentsSidebar from './AssignmentsSidebar.vue'
import dayjs from 'dayjs'

@Component({
  components: { CUCollectionTable, HoldUpModal },
  metaInfo: {
    title: 'Reservations',
  },
})
export default class ReservationsList extends mixins(DateMixin) {
  @Provide() limitTableToViewHeight = true
  @Provide() mobileRowBreakpoint = 1570
  @Provide() isPdfExportEnabled = app.isReservationPdfExportEnabled

  created(): void {
    const QUOTE_COLUMN_ID = '596a864c-2608-4232-a125-e8233e2c5fad'
    if (
      auth?.getUserRoleNames != null &&
      !auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.QUOTES)
    ) {
      this.columns = this.columns.filter(
        (column) => column._t_id !== QUOTE_COLUMN_ID
      )
    }
  }

  mounted(): void {
    EventBus.$on('reservations:open-contact', this.openContact)
    EventBus.$on('reservations:open-billing-contact', this.openBillingContact)
    EventBus.$on('reservations:open-company', this.openCompany)
    EventBus.$on('reservations:open-assignments', this.openAssignments)
    EventBus.$on('reservations:clear-assignments', this.handleClearAssignments)
    EventBus.$on('reservations:assign-trips', this.assignTrips)
    EventBus.$on('reservations:bulk-invoice', this.handleBulkInvoice)
    EventBus.$on(
      'reservations:process-payment-bulk',
      this.handleProcessPaymentBulk
    )
    EventBus.$on('reservations:set-status', this.openSetStatusSidebar)
    EventBus.$on('reservations:log-payment-bulk', this.handleLogPaymentBulk)
    EventBus.$on('reservations:add-refund-bulk', this.handleAddRefundBulk)
    EventBus.$on(
      'reservations:change-due-date-bulk',
      this.handleChangeDueDateBulk
    )
    EventBus.$on('reservations:delete', this.handleDeleteReservations)
    EventBus.$on('reservations:duplicate', this.handleDuplicateReservation)
    EventBus.$on('reservations:openSidebarSummary', this.openSidebarSummary)
    EventBus.$on('reservations:reopen-assignment-sidebar', this.openAssignments)
    EventBus.$on(
      'reservations:download-trip-sheet',
      this.downloadReservationsTripSheet
    )

    if (this.$route.hash) {
      this.$router.replace({ hash: '' })
    }

    column.setIsDownloadEnabled(this.canExportReservations)
    column.setIsExportSummaryEnabled(this.canExportReservationSummary)
  }

  beforeDestroy(): void {
    EventBus.$off('reservations:open-contact', this.openContact)
    EventBus.$off('reservations:open-billing-contact', this.openBillingContact)
    EventBus.$off('reservations:open-company', this.openCompany)
    EventBus.$off('reservations:open-assignments', this.openAssignments)
    EventBus.$off('reservations:clear-assignments', this.handleClearAssignments)
    EventBus.$off('reservations:assign-trips', this.assignTrips)
    EventBus.$off('reservations:bulk-invoice', this.handleBulkInvoice)
    EventBus.$off(
      'reservations:process-payment-bulk',
      this.handleProcessPaymentBulk
    )
    EventBus.$off('reservations:set-status', this.openSetStatusSidebar)
    EventBus.$off('reservations:log-payment-bulk', this.handleLogPaymentBulk)
    EventBus.$off('reservations:add-refund-bulk', this.handleAddRefundBulk)
    EventBus.$off(
      'reservations:change-due-date-bulk',
      this.handleChangeDueDateBulk
    )
    EventBus.$off('reservations:delete', this.handleDeleteReservations)
    EventBus.$off('reservations:duplicate', this.handleDuplicateReservation)
    EventBus.$off('reservations:openSidebarSummary', this.openSidebarSummary)
    EventBus.$off(
      'reservations:reopen-assignment-sidebar',
      this.openAssignments
    )
    EventBus.$off(
      'reservations:download-trip-sheet',
      this.downloadReservationsTripSheet
    )
  }

  tableView = reservations.tableView
  initialSavedViews: SavedView[] = initialSavedViews
  columns: DataTableColumn[] = columns(
    this.formatMediumDateLongTime,
    this.formatLongTime
  )

  selectedReservations: Reservation[]
  isClearAssignmentModalOpen = false
  isDeleteReservationModalOpen = false
  count = 0
  selectedReservationsCount = null

  get canExportReservations(): boolean {
    return !!auth.roles.find(
      (role) => role.roleName === 'can_export_reservations'
    )
  }

  get canExportReservationSummary(): boolean {
    return !!auth.roles.find(
      (role) => role.roleName === 'can_export_reservation_summary'
    )
  }

  downloadReservationsList(): any {
    if (!this.canExportReservations) {
      return () => null
    }
    return reservations.export
  }

  handlePdfDownload(items: Reservation[]): void {
    const hashes = items.map((r) => r.hash)
    const url = reservations.getExportPdfUrl(
      hashes,
      auth.getCompanyId,
      auth.getUserTimeZone
    )
    window.open(url, '_blank')
  }

  openContact(row: Reservation): void {
    sidebar.popAllAndPush({
      component: ContactSidebarDetail,
      props: {
        userId: row.customerId,
        showContactsTVButton: false,
        simple: true,
      },
      on: { refresh: () => EventBus.$emit('refresh-tableview') },
    })
  }

  refreshTable(): void {
    EventBus.$emit('refresh-tableview')
  }

  openBillingContact(row: Reservation): void {
    sidebar.popAllAndPush({
      component: ContactSidebarDetail,
      props: {
        userId: row.billingCustomerId,
        showContactsTVButton: false,
        simple: true,
      },
      on: { refresh: () => EventBus.$emit('refresh-tableview') },
    })
  }

  openCompany(row: Reservation): void {
    sidebar.popAllAndPush({
      component: CompanySidebarDetail,
      props: {
        customerAccountId: row.customerAccountId,
      },
      on: { refresh: () => EventBus.$emit('refresh-tableview') },
    })
  }

  async openAssignments(row: Reservation): Promise<void> {
    assignment.clearSelected()
    assignment.setIsNonDefaultView(true)

    sidebar.popAllAndPush({
      component: AssignmentsSidebar,
      persist: true,
      width: 512,
      on: { close: this.handleCloseAssignments },
    })

    assignment.setCurrentDate(
      dayjs(
        row.garageDepartureTime || row.pickupDate || row.dropoffDate || dayjs()
      )
    )
    await assignment.refresh()
    await assignment.setSelectedIds({
      reservationId: row.reservationId,
      vehicleId: null,
      vehicleIdx: 0,
    })

    if (this.$router.currentRoute.hash !== `#${row.reservationId}`) {
      this.$router.replace({ hash: `#${row.reservationId}` })
    }
  }

  downloadReservationsTripSheet(reservationHashes: string[]): void {
    reservations.openDriverItineraries(reservationHashes)
  }

  handleCloseAssignments(): void {
    this.$router.replace({ hash: '' })
  }

  handleClearAssignments(reservations: Reservation[]): void {
    this.selectedReservations = reservations
    this.isClearAssignmentModalOpen = true
  }

  handleDeleteReservations(reservations: Reservation[]): void {
    this.selectedReservationsCount = reservations.length
    this.selectedReservations = reservations
    this.isDeleteReservationModalOpen = true
  }

  async handleDuplicateReservation(
    selectedReservations: Reservation[]
  ): Promise<void> {
    if (selectedReservations.length !== 1) {
      return
    }
    try {
      const res = await reservations.duplicateReservation(
        selectedReservations[0].reservationId
      )
      if (res.status === 200) {
        const route = this.$router.resolve({
          name: 'reservations.detail',
          params: { id: res.data },
        })
        window.open(route.href)
      }
    } catch (e) {
      console.error(e)
      EventBus.$emit('snackbar:error', 'Error duplicating reservation')
    }
  }

  openSetStatusSidebar(reservations: Reservation[]): void {
    this.selectedReservations = reservations
    sidebar.popAllAndPush({
      component: SetReservationStatusSidebar,
      on: {
        'set:status': this.setStatus,
      },
      title: 'Set Reservation Status',
    })
  }

  async setStatus(status: Status): Promise<void> {
    const statusUpdate: ReservationStatusUpdate = {
      reservationIds: this.selectedReservations.map((r) => r.reservationId),
      reservationStatus: status.key,
    }
    await reservations.updateReservationStatus(statusUpdate)
    EventBus.$emit('snackbar:success', 'Statuses set successfully!')
    EventBus.$emit('refresh-tableview')
  }

  async clearAssignments(): Promise<void> {
    const reservationIds = this.selectedReservations.map(
      ({ reservationId }) => reservationId
    )
    if (!reservationIds.length) {
      this.isClearAssignmentModalOpen = false
      return
    }
    try {
      await reservations.deleteAssignmentByReservationIds(reservationIds)
      EventBus.$emit('refresh-tableview')
      EventBus.$emit('snackbar:success', 'Assignments cleared successfully!')
    } catch {
      EventBus.$emit(
        'snackbar:error',
        'There was a problem clearing the assignments.'
      )
    } finally {
      this.isClearAssignmentModalOpen = false
    }
  }

  deleteReservations(): void {
    if (this.selectedReservationsCount === 0) {
      this.isDeleteReservationModalOpen = false
      return
    }
    const payload = {
      reservationIds: this.selectedReservations.flatMap(
        (reservation) => reservation.reservationId
      ),
    }
    reservations.deactivateReservations(payload)
    this.isClearAssignmentModalOpen = false
    EventBus.$emit('refresh-tableview')
    EventBus.$emit(
      'snackbar:success',
      `${pluralize(
        this.selectedReservationsCount,
        'Reservation'
      )} deleted successfully!`
    )
  }

  handleBulkInvoice(rows: Reservation[]): void {
    sidebar.popAllAndPush({
      component: CreateInvoiceSidebar,
      props: {
        bulkReservations: rows,
      },
      title: 'Create Invoice',
    })
  }

  validateSingleCustomerForReservations(rows: Reservation[]): boolean {
    let customerIds = rows.map((r) => r.customerId)
    customerIds = [...new Set(customerIds)]
    if (customerIds.length > 1) {
      EventBus.$emit(
        'snackbar:error',
        'Please select a single customer to proceed'
      )
      return false
    }
    return true
  }

  validateFullyPaidOffReservations(rows: Reservation[]): boolean {
    const fullyPaidOff = rows
      .filter((r) => r.balance === 0)
      .map((r) => r.managedId)
    if (fullyPaidOff.length) {
      EventBus.$emit(
        'snackbar:error',
        `The following reservations are fully paid off and may not be included in this action: ${fullyPaidOff.join(
          ', '
        )}`
      )
      return false
    }
    return true
  }

  handleProcessPaymentBulk(rows: Reservation[]): void {
    if (
      !this.validateSingleCustomerForReservations(rows) ||
      !this.validateFullyPaidOffReservations(rows)
    ) {
      return
    }

    sidebar.popAllAndPush({
      component: ReservationProcessPaymentSidebar,
      props: {
        bulkReservations: rows,
      },
      title: 'Process Payment',
    })
  }

  handleLogPaymentBulk(rows: Reservation[]): void {
    if (
      !this.validateSingleCustomerForReservations(rows) ||
      !this.validateFullyPaidOffReservations(rows)
    ) {
      return
    }

    sidebar.popAllAndPush({
      component: LogPaymentSidebar,
      props: {
        bulkReservations: rows,
      },
      title: 'Log Payment',
    })
  }

  handleAddRefundBulk(rows: Reservation[]): void {
    if (!this.validateSingleCustomerForReservations(rows)) {
      return
    }
    sidebar.popAllAndPush({
      component: AddRefundSidebar,
      props: {
        bulkReservations: rows,
      },
      title: 'Log Refund',
    })
  }

  handleChangeDueDateBulk(rows: Reservation[]): void {
    if (
      !this.validateSingleCustomerForReservations(rows) ||
      !this.validateFullyPaidOffReservations(rows)
    ) {
      return
    }

    sidebar.popAllAndPush({
      component: ChangeDueDateSidebar,
      props: {
        bulkReservations: rows,
      },
      title: 'Change Due Date',
    })
  }

  assignTrips(rows: Reservation[]): void {
    sidebar.popAllAndPush({
      component: Placeholder,
      props: {
        reservations: rows,
      },
      title: 'Assign Trips',
    })
  }

  isCharterUpReferral(reservation: Reservation): boolean {
    return (
      !!reservation && reservation?.sourceCategory === SourceCategory.REFERRAL
    )
  }

  isActionDisabled(reservations: Reservation[] = []): boolean {
    return reservations
      .map(this.isCharterUpReferral)
      .reduce((acc, isReferral) => acc || isReferral, false)
  }

  actionTooltip(reservations: Reservation[] = []): string {
    return (
      this.isActionDisabled(reservations) &&
      'Action not available for Referrals'
    )
  }

  openSidebarSummary(resIds: {
    reservationId: number
    managedId: string
  }): void {
    sidebar.popAllAndPush({
      component: ReservationDetailPanel,
      props: {
        ...resIds,
        onUpdate: this.refreshTable,
      },
      width: 764,
      wide: true,
      persist: true,
      onClose: () => reservation.clear(),
    })
  }

  get actions(): TableAction[] {
    const actions: TableAction[] = [
      {
        displayText: 'Delete',
        key: 'delete-reservations',
        icon: 'delete',
        iconWidth: '20px',
        iconHeight: '20px',
        action: (rows: Reservation[]): void => {
          EventBus.$emit('reservations:delete', rows)
        },
      },
      // POST LAUNCH
      // {
      //   displayText: 'Assign Trips',
      //   key: 'assign-reservation-trips',
      //   icon: 'confirm',
      //   action: (rows: Reservation[]): void => {
      //     EventBus.$emit('reservations:assign-trips', rows)
      //   },
      // },
      {
        displayText: 'Clear Assignments',
        key: 'clear-assignments',
        icon: 'close',
        action: (rows: Reservation[]): void => {
          EventBus.$emit('reservations:clear-assignments', rows)
        },
      },
      {
        displayText: 'Set Status',
        key: 'set-reservation-status',
        icon: 'edit',
        tooltip: this.actionTooltip,
        disabled: this.isActionDisabled,
        action: (rows: Reservation[]): void => {
          EventBus.$emit('reservations:set-status', rows)
        },
      },
      {
        displayText: 'Invoice',
        key: 'invoice',
        icon: 'invoice',
        isSingleContactOnly: true,
        tooltip: this.actionTooltip,
        disabled: this.isActionDisabled,
        action: (rows: Reservation[]): void => {
          EventBus.$emit('reservations:bulk-invoice', rows)
        },
      },
      {
        displayText: 'Payment Actions',
        key: 'payment-actions',
        icon: 'payment',
        tooltip: this.actionTooltip,
        disabled: this.isActionDisabled,
        dropdownItems: [
          {
            displayText: 'Process Payment',
            key: 'process-payment',
            icon: '',
            action: (rows: Reservation[]): void => {
              EventBus.$emit('reservations:process-payment-bulk', rows)
            },
          },
          {
            displayText: 'Log Payment',
            key: 'log-payment',
            icon: '',
            action: (rows: Reservation[]): void => {
              EventBus.$emit('reservations:log-payment-bulk', rows)
            },
          },
          // POST LAUNCH
          // { displayText: 'Add Charge', key: 'add-charge', icon: '' },
          // { displayText: 'Reduce Charge', key: 'reduce-charge', icon: '' },
          // {
          //   displayText: 'Add Refund',
          //   key: 'add-refund',
          //   icon: '',
          //   action: (rows: Reservation[]): void => {
          //     EventBus.$emit('reservations:add-refund-bulk', rows)
          //   },
          // },
          // {
          //   displayText: 'Change Due Date',
          //   key: 'change-due-date',
          //   icon: '',
          //   action: (rows: Reservation[]): void => {
          //     EventBus.$emit('reservations:change-due-date-bulk', rows)
          //   },
          // },
        ],
      },
      {
        displayText: 'Download Trip Sheet',
        key: 'download-trip-sheet',
        icon: 'pdf',
        tooltip: this.actionTooltip,
        action: (rows: Reservation[]): void => {
          EventBus.$emit(
            'reservations:download-trip-sheet',
            rows.map((r) => r.hash)
          )
        },
      },
    ]

    if (app.isDuplicateReservationEnabled) {
      // If Duplicate Reservation is an action, slot it immediately after Delete
      let deleteReservationsIndex = actions.findIndex(
        (action) => action.key === 'delete-reservations'
      )
      deleteReservationsIndex =
        deleteReservationsIndex === -1
          ? actions.length
          : deleteReservationsIndex

      actions.splice(deleteReservationsIndex + 1, 0, {
        displayText: 'Duplicate Reservation',
        key: 'duplicated',
        icon: 'duplicate',
        iconWidth: '16px',
        iconHeight: '16px',
        tooltip: this.actionTooltip,
        disabled: this.isActionDisabled,
        isSingleSelectOnly: true,
        action: (rows: Reservation[]): void => {
          EventBus.$emit('reservations:duplicate', rows)
        },
      })
    }
    return actions
  }

  destroyed(): void {
    reservation.clear()
  }
}
