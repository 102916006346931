
import { Reservation } from '@/models/dto/Reservation'
import { Vue, Component, Prop } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import EditUserSidebar from './EditUserSidebar.vue'
import driver from '@/services/driver'
import {
  collapseAssignments,
  getFilteredAssignmentsFromRow,
} from '@/utils/assignment'
import { RowAssignment, RowDriver } from '@/models/dto'

@Component({})
export default class ReservationsListDriverAssignments extends Vue {
  EventBus = EventBus
  @Prop({
    required: true,
  })
  row: Reservation

  get assignments(): RowAssignment[] {
    return collapseAssignments(getFilteredAssignmentsFromRow(this.row))
  }

  get canViewDrivers(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.OPERATIONS)
  }

  getDriverNameClasses(assignment: RowDriver): string {
    const cursor = 'cursor-pointer'
    if (assignment.statusId === 3 || !assignment.id) {
      return `${cursor} text-additional-red`
    } else if (assignment.statusId === 2) {
      return `${cursor} text-secondary`
    } else if (assignment.statusId === 1) {
      return `${cursor} text-bg-gray-6`
    } else {
      return `${cursor} text-secondary`
    }
  }

  async openDriverPreview(driverId: number): Promise<void> {
    if (!driverId) {
      return
    }
    const res = await driver.byId(driverId)
    sidebar.push({
      component: EditUserSidebar,
      props: { user: res.data.driver, driverOnly: true },
      width: 675,
    })
  }
}
