
import { Reservation } from '@/models/dto/Reservation'
import { Vue, Component, Prop } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import VehicleSidebar from './VehicleSidebar.vue'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import {
  collapseAssignments,
  getFilteredAssignmentsFromRow,
} from '@/utils/assignment'
import { RowAssignment } from '@/models/dto'

@Component({})
export default class ReservationsListVehicleAssignments extends Vue {
  @Prop({ required: true }) row: Reservation
  EventBus = EventBus

  get canViewVehicles(): boolean {
    return auth.getUserRoleNames?.includes(ACCESS_SETTINGS_ROLES.OPERATIONS)
  }

  get assignments(): RowAssignment[] {
    return collapseAssignments(getFilteredAssignmentsFromRow(this.row))
  }

  openVehiclePreview(vehicleId: number, vehicleName: string): void {
    sidebar.push({
      component: VehicleSidebar,
      width: 672,
      props: {
        activeVehicleDetailId: vehicleId,
        isModeEdit: true,
        showEditHeader: false,
      },
      title: vehicleName,
    })
  }

  handleRefresh(): void {
    sidebar.pop()
    EventBus.$emit('refresh-tableview')
  }

  mounted(): void {
    EventBus.$on('vehicles:refresh', this.handleRefresh)
  }

  beforeDestroy(): void {
    EventBus.$off('vehicles:refresh', this.handleRefresh)
  }
}
