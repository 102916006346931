
import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({
  components: {},
})
export default class ListTags extends Vue {
  @Prop({ required: true }) tagsString!: string
  tagList = []

  mounted(): void {
    this.tagList = this.tagsString ? this.tagsString.split('::') : []
  }
}
