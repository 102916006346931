
import { reservationStatusTypes } from '@/data/types'
import { Reservation } from '@/models/dto'
import { ReservationStatus } from '@/utils/enum'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ReservationStatusLabel extends Vue {
  @Prop({ required: true }) row: Reservation

  get label(): string {
    return reservationStatusTypes
      .find((t) => t.key === this.row.reservationStatus)
      ?.label.toUpperCase()
  }

  get color(): { fg: string; bg: string } {
    switch (this.row.reservationStatus) {
      case ReservationStatus.Confirmed:
        return { fg: 'bronze', bg: 'beige' }
      case ReservationStatus.Printed:
        return { fg: 'navy', bg: 'navy-10' }
      case ReservationStatus.InProgress:
        return { fg: 'sky', bg: 'sky-10' }
      case ReservationStatus.Completed:
        return { fg: 'grass', bg: 'grass-10' }
      case ReservationStatus.Closed:
        return { fg: 'fuscia', bg: 'fuscia-10' }
      case ReservationStatus.Cancelled:
        return { fg: 'rust', bg: 'rust-10' }
      case ReservationStatus.Hold:
        return { fg: 'additional-yellow', bg: 'additional-yellow-10' }
      default:
        return { fg: 'text-dark-gray', bg: 'bg-gray-1' }
    }
  }
}
