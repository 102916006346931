
import ReservationsList from '@/components/ReservationsList.vue'
import { Component, Vue } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'

@Component({
  components: {
    ReservationsList,
    Main,
  },
})
export default class Reservations extends Vue {}
