
import { Reservation } from '@/models/dto/Reservation'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AffiliateOffer } from '@/models/dto/Affiliate'
import { currencyFilter } from '@/utils/string'


@Component({})
export default class ReservationsListAffiliateAmounts extends Vue {
  @Prop({
    required: true,
  })
  row: Reservation

  offers: AffiliateOffer[] = []

  formatAmount(amount: number): string {
    return currencyFilter(amount)
  }

  get affiliateOffers(): AffiliateOffer[] {
    return this.row.affiliateOffers.filter((offer) => offer.isActive)
  }
}
