import { render, staticRenderFns } from "./ListTags.vue?vue&type=template&id=15651f25"
import script from "./ListTags.vue?vue&type=script&lang=ts"
export * from "./ListTags.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports