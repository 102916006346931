
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUDataTableLink from '@/components/CUDataTableLink.vue'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: { CUDataTableLink },
})
export default class ResListResId extends Vue {
  @Prop({ type: Boolean, default: false }) displayUnread!: boolean
  @Prop({ required: false }) readonly reservationId: number
  @Prop({ required: false }) readonly managedId: string
  @Prop({ required: false }) readonly affiliateOffers: number
  @Prop({ required: false, default: true }) readonly showPreview: boolean

  hovering = false

  openSidebar(): void {
    if (this.reservationId || this.managedId) {
      EventBus.$emit('reservations:openSidebarSummary', {
        reservationId: this.reservationId,
        managedId: this.managedId,
      })
    }
  }
}
