import { DataTableColumn } from '@/models/DataTableColumn'
import { SavedView } from '@/models/dto/SavedView'
import ReservationsListAssignedIcon from '@/components/ReservationsListAssignedIcon.vue'
import GrayBus from '@/assets/icons/GrayBus.vue'
import CUDataTableLink from '@/components/CUDataTableLink.vue'
import ResListResId from '@/components/ResListResId.vue'
import { Reservation } from '@/models/dto/Reservation'
import { RawLocation } from 'vue-router'
import { currencyFilter } from '@/utils/string'
import ReservationsListTotalDue from '@/components/ReservationsListTotalDue.vue'
import ReservationsListVehicleAssignments from '@/components/ReservationsListVehicleAssignments.vue'
import ReservationsListDriverAssignments from '@/components/ReservationsListDriverAssignments.vue'
import ReservationsListAffiliateContacts from '@/components/ReservationsListAffiliateContacts.vue'
import ReservationsListAffiliateAmounts from '@/components/ReservationsListAffiliateAmounts.vue'
import ReservationsListBalance from '@/components/ReservationsListBalance.vue'
import ReservationStatusLabel from '@/components/ReservationStatusLabel.vue'
import {
  drivingTimeLabel,
  calculatePlannedDays,
  calculateEstimateTripTime,
} from '@/utils/time'
import { metersToMiles } from '@/utils/distance'
import ListTags from '@/components/ListTags.vue'
import auth from '@/store/modules/auth'
import { CURRENCY_COLUMN_WIDTH, DATE_COLUMN_WIDTH } from './tableViews'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import { predefinedFutureDate } from '@/utils/datePicker'
import { DateOptions } from '@/models/Date'
import dayjs from 'dayjs'
import { reservationStatusTypes } from './types'

const CAN_VIEW_CONTACTS = auth.getUserRoleNames.includes(
  ACCESS_SETTINGS_ROLES.CONTACTS
)

export const columns = (
  formatMediumDateLongTime: (date: string, opts: DateOptions) => string,
  formatLongTime: (date: string, opts: DateOptions) => string
): DataTableColumn[] => [
  {
    _t_id: '2d70234a-51a6-483e-8e3d-c89d2402841f',
    text: 'Res. ID',
    value: 'managedId',
    type: 'slot',
    columnSlotComponent: ResListResId,
    columnSlotProps: (row: Reservation): Record<string, unknown> => ({
      displayUnread: false,
      reservationId: row.reservationId,
      managedId: row.managedId,
      affiliateOffers:
        row.affiliateOffers?.filter((value) => value.isActive)?.length || 0,
    }),
    linkLocation: (row: Reservation): RawLocation => ({
      name: 'reservations.detail',
      params: { id: String(row.reservationId) },
    }),
    linkToNewTab: true,
    hidden: false,
    required: true,
    filterProp: 'managedId',
    filterBySearch: true,
    filterable: true,
  },
  {
    _t_id: 'e7701736-b904-4ede-8d55-9373e7abab78',
    text: 'Res. Status',
    value: 'reservation-status-key',
    type: 'slot',
    columnSlotComponent: ReservationStatusLabel,
    hidden: false,
    filterable: true,
    filterInterface: 'multi-select',
    filterDropdownItems: reservationStatusTypes.map((s) => ({
      text: s.label,
      value: s.key,
    })),
    filterProp: 'reservationStatusKey',
    sortable: false,
    filterType: 'eq',
    filterValue: [],
  },
  {
    _t_id: 'c6f6ae39-1ef3-4ea7-91ce-3af6240704d8',
    text: 'Assignment Status',
    value: 'reservation-assignment-icons',
    type: 'slot',
    columnSlotComponent: ReservationsListAssignedIcon,
    headerComponent: GrayBus,
    hidden: false,
    filterable: true,
    sortable: false,
    filterProp: ['assignedDriverPercentage', 'assignedVehiclePercentage'],
    filterInterface: 'select',
    filterDropdownItems: [
      {
        text: 'Needs Assignment',
        value: '100.00',
        filterType: 'neq',
        filterLogic: 'or',
      },
      {
        text: 'Fully Assigned',
        value: '100',
        filterType: 'eq',
        filterLogic: 'and',
      },
    ],
    filterType: 'eq',
    filterValue: -1,
    filterHiddenOnTopBar: false,
    omitExport: true,
  },
  {
    _t_id: '81d36464-ef9f-4af8-bbd1-9c13a9c92433',
    text: 'Company',
    value: 'customerAccountName',
    type: CAN_VIEW_CONTACTS ? 'link' : 'text',
    clickEvent: 'reservations:open-company',
    hidden: false,
    filterBySearch: true,
    filterable: true,
    filterProp: 'customerAccountName',
    unsetWidthOnBreakpoint: 'xlOnly',
  },
  {
    _t_id: '24d55b6c-c683-4e56-b0ba-f1e0f5901589',
    text: 'Booking Contact',
    value: 'customerName',
    type: CAN_VIEW_CONTACTS ? 'link' : 'text',
    clickEvent: 'reservations:open-contact',
    hidden: false,
    filterable: true,
    filterBySearch: true,
    filterProp: 'customerName',
    unsetWidthOnBreakpoint: 'xlOnly',
  },
  {
    _t_id: '0d3e73cc-25a0-4514-a754-3dccaa709e2f',
    text: 'Billing Contact',
    value: 'billingCustomerName',
    type: CAN_VIEW_CONTACTS ? 'link' : 'text',
    clickEvent: 'reservations:open-billing-contact',
    hidden: true,
    filterable: true,
    filterBySearch: true,
    filterProp: 'billingCustomerName',
    unsetWidthOnBreakpoint: 'xlOnly',
  },
  {
    _t_id: '81765aa3-dbbd-4655-9bb3-69e4ce17b138',
    text: 'Pickup',
    value: 'pickupDate',
    computedText: (row): string =>
      formatMediumDateLongTime(row.pickupDate, {
        tz: row.firstPickupTimeZone,
        showDot: true,
        showMeridianUpper: true,
      }) || '',
    hidden: false,
    filterable: true,
    filterInterface: 'date-range',
    filterProp: 'pickupDate',
    filterValue: [],
    filterHiddenOnTopBar: false,
  },
  {
    _t_id: '75b6450f-b22b-408d-8093-dd3446ddb23b',
    text: 'Total Due',
    value: 'amount',
    columnSlotComponent: ReservationsListTotalDue,
    hidden: false,
  },
  {
    _t_id: '48bb7889-7754-4a13-ad4e-49d05dc88992',
    text: 'Base Fare',
    value: 'baseFare',
    computedText: (row): string => currencyFilter(row.baseFare),
    hidden: true,
  },
  {
    _t_id: '3b6e4ec1-4786-4901-8bb8-2824b63e4165',
    text: 'Balance',
    value: 'balance',
    columnSlotComponent: ReservationsListBalance,
    hidden: false,
    filterable: true,
    filterInterface: 'price-range',
    filterProp: 'balance',
    filterValue: [],
    filterHiddenOnTopBar: true,
  },
  {
    _t_id: 'c5eceed4-f761-4b62-bcac-8e56cce0ee90',
    text: 'Vehicle',
    value: 'vehicleAssignmentsString',
    type: 'slot',
    columnSlotComponent: ReservationsListVehicleAssignments,
    hidden: false,
    unsetWidthOnBreakpoint: 'xlOnly',
    filterBySearch: true,
    filterable: true,
    filterProp: 'vehicleAssignmentsString',
    sortable: false,
    omitExport: false,
  },
  {
    _t_id: '8435e3d9-23ae-40a8-8c48-57c66f2ae1ea',
    text: 'Driver',
    value: 'driverAssignmentsString',
    type: 'slot',
    columnSlotComponent: ReservationsListDriverAssignments,
    hidden: false,
    unsetWidthOnBreakpoint: 'xlOnly',
    filterBySearch: true,
    filterable: true,
    filterProp: 'driverAssignmentsString',
    sortable: false,
    omitExport: false,
  },
  {
    _t_id: '2042af9b-2f51-483f-8d5e-1756c226662f',
    text: 'Last Activity',
    value: 'updatedOn',
    computedText: (row): string =>
      formatMediumDateLongTime(row.updatedOn, {
        tz: auth.getUserTimeZone,
        showDot: true,
        showMeridianUpper: true,
      }),
    hidden: false,
  },
  {
    _t_id: '1dfb99a9-a229-43cf-84f4-81d47d7d217a',
    text: 'Assigned Vehicles',
    value: 'assignedVehiclePercentage',
    invisible: true,
    hidden: true,
    filterable: true,
    filterProp: 'assignedVehiclePercentage',
    filterInterface: 'select',
    filterDropdownItems: [
      { text: 'Needs Assignment', value: '100.00', filterType: 'neq' },
      { text: 'Fully Assigned', value: '100', filterType: 'eq' },
    ],
    filterType: 'eq',
    filterValue: -1,
    filterHiddenOnTopBar: true,
    omitExport: true,
    width: 0,
  },
  {
    _t_id: '4da2480b-412c-499b-a05a-0ea9c56dc8dc',
    text: 'Assigned Drivers',
    value: 'assignedDriverPercentage',
    invisible: true,
    hidden: true,
    filterable: true,
    filterProp: 'assignedDriverPercentage',
    filterInterface: 'select',
    filterDropdownItems: [
      { text: 'Needs Assignment', value: '100.00', filterType: 'neq' },
      { text: 'Fully Assigned', value: '100', filterType: 'eq' },
    ],
    filterType: 'eq',
    filterValue: -1,
    filterHiddenOnTopBar: true,
    omitExport: true,
    width: 0,
  },
  {
    _t_id: 'b4a8bb47-5011-455b-919f-e910840e4716', // need to surface due date to support highlighting when overdue
    text: 'Payment Status',
    value: 'paymentStatus',
    computedText: (row) => {
      switch (row.paymentStatus) {
        case 'not_paid':
          return 'Not Paid'
        case 'partially_paid':
          return 'Partially Paid'
        default:
          return 'Fully Paid'
      }
    },
    hidden: true,
    filterable: true,
    filterInterface: 'multi-select',
    filterProp: 'paymentStatus',
    filterDropdownItems: [
      { text: 'Not Paid', value: 'not_paid' },
      { text: 'Partially Paid', value: 'partially_paid' },
      { text: 'Fully Paid', value: 'fully_paid' },
    ],
    filterType: 'eq',
    filterValue: [],
    filterHiddenOnTopBar: false,
  },
  {
    _t_id: 'd14b91cb-3bb3-4aa2-8d19-b07739c19a92',
    text: 'Booked By',
    value: 'bookedByName',
    computedText: (row): string =>
      row.bookedByName !== ' ' ? row.bookedByName : 'Customer',
    type: 'text',
    filterProp: 'bookedByName',
    filterBySearch: true,
    hidden: true,
  },
  {
    _t_id: '7fc5f8d6-518d-4719-9d69-dd522260a6a8',
    text: 'Created Date',
    value: 'createdOn',
    computedText: (row): string =>
      formatMediumDateLongTime(row.createdOn, {
        tz: auth.getUserTimeZone,
        showDot: true,
        showMeridianUpper: true,
      }),
    hidden: true,
    filterable: true,
    filterInterface: 'date-range',
    filterProp: 'createdOn',
    filterValue: [],
    filterHiddenOnTopBar: true,
  },
  {
    _t_id: '63252c8d-ce34-4aba-bdba-c956dbd69c46',
    text: 'Customer Email',
    value: 'customerEmail',
    filterBySearch: true,
    filterProp: 'customerEmail',
    type: 'text',
    hidden: true,
  },
  {
    _t_id: 'afe96b24-5112-4f37-b12d-c0f76280a18b',
    text: 'Cancelled Date',
    value: 'cancelledOn',
    computedText: (row): string =>
      formatMediumDateLongTime(row.cancelledOn, {
        showDot: true,
        showMeridianUpper: true,
      }),
    hidden: true,
    filterable: true,
    filterInterface: 'date-range',
    filterProp: 'cancelledOn',
    filterValue: [],
    filterHiddenOnTopBar: true,
  },
  {
    _t_id: 'f1b1711c-61b0-440b-9e1d-1141142ee849',
    text: 'Estimated Trip Time',
    value: '--',
    computedText: (row) => calculateEstimateTripTime(row),
    hidden: true,
    sortable: false,
  },
  {
    _t_id: 'c6719b3e-ae9d-4f9f-978a-895e9638b7e6',
    text: 'Planned Days',
    value: null,
    computedText: (row) => calculatePlannedDays(row),
    hidden: true,
    sortable: false,
  },
  {
    _t_id: '449766e5-fb0a-478e-b0c8-c4ee5f5cc137',
    text: 'Driving Time',
    value: 'drivingTime',
    computedText: (row): string => drivingTimeLabel(row.drivingTime),
    hidden: true,
  },
  {
    _t_id: 'a0da93dc-9480-4133-abc8-960961652791',
    text: 'Distance',
    value: 'distance',
    computedText: (row): string =>
      `${metersToMiles(row.distance).toFixed(2)} mi.`,
    hidden: true,
  },
  {
    _t_id: '596a864c-2608-4232-a125-e8233e2c5fad',
    text: 'Quote ID',
    value: 'quoteManagedId',
    type: 'slot',
    computedText: (row: Reservation): string => {
      return row.quoteManagedId.toString()
    },
    columnSlotComponent: CUDataTableLink,
    linkLocation: (row: Reservation): RawLocation => ({
      name: 'quotes.detail',
      params: { id: String(row.quoteId) },
    }),
    linkToNewTab: true,
    hidden: true,
    filterProp: 'quoteManagedId',
    filterable: true,
    filterBySearch: true,
    filterType: 'eq',
    filterPropertyType: 'number',
  },
  // { Commenting out for now, we may use this later
  //   _t_id: '9d38b01a-40e7-4f2f-9c3d-e76fd4eb034b',
  //   text: 'Contract ID',
  //   value: 'contractId',
  //   type: 'text',
  //   filterProp: 'contractId',
  //   filterable: true,
  //   filterBySearch: true,
  //   filterType: 'eq',
  //   filterPropertyType: 'number',
  //   hidden: true,
  // },
  {
    _t_id: '40ab59df-9105-47cd-9000-d0776de3ae88',
    text: 'Due Date',
    value: 'dueDate',
    computedText: (row): string =>
      formatMediumDateLongTime(row.dueDate, {
        showDot: true,
        showMeridianUpper: true,
        tz: row.firstPickupTimeZone,
      }),
    filterable: true,
    filterInterface: 'date-range',
    filterProp: 'dueDate',
    filterValue: [],
    filterHiddenOnTopBar: true,
    hidden: true,
  },
  {
    _t_id: '9c5a5a08-6e0f-4487-b459-c1bed2b82ed6',
    text: 'Trip Type',
    value: 'tripTypeLabel',
    type: 'text',
    filterable: true,
    filterInterface: 'select',
    filterProp: 'tripTypeLabel',
    filterDropdownItems: [
      { text: 'One Way', value: 'One Way' },
      { text: 'Round Trip', value: 'Round Trip' },
      { text: 'Hourly', value: 'Hourly' },
      { text: 'Daily', value: 'Daily' },
      { text: 'Multi-day', value: 'Multi-day' },
      { text: 'Shuttle', value: 'Shuttle' },
    ],
    filterType: 'eq',
    filterValue: -1,
    filterHiddenOnTopBar: true,
    hidden: true,
  },
  {
    _t_id: '0844dbdb-c99a-48b4-af1b-7c1bbd05369d',
    text: 'Pickup Location',
    value: 'pickupLocation',
    hidden: true,
    filterBySearch: true,
    filterProp: 'pickupLocation',
    type: 'text',
  },
  {
    _t_id: 'a653f114-c2a0-4234-94f8-305177c239ce',
    text: 'Garage',
    value: 'garageArrivalTime',
    computedText: (row): string =>
      formatLongTime(row.garageArrivalTime, {
        showMeridianUpper: true,
        tz: row.garageDepartureTimeZone,
      }),
    filterable: true,
    filterInterface: 'date-range',
    filterProp: 'garageArrivalTime',
    filterValue: [],
    filterHiddenOnTopBar: true,
    hidden: true,
  },
  {
    _t_id: '1a20c556-388b-41ff-a227-7ec97f4c5639',
    text: 'Spot',
    value: 'firstPickupSpotTime',
    computedText: (row): string =>
      formatMediumDateLongTime(row.firstPickupSpotTime, {
        showDot: true,
        showMeridianUpper: true,
        tz: row.firstPickupTimeZone,
      }),
    filterable: true,
    filterInterface: 'date-range',
    filterProp: 'firstPickupSpotTime',
    filterValue: [],
    filterHiddenOnTopBar: true,
    hidden: true,
  },
  {
    _t_id: '550eceb4-ebca-4322-a749-a324ca694564',
    text: 'First Drop',
    value: 'dropoffDate',
    computedText: (row): string =>
      formatLongTime(row.dropoffDate, {
        showMeridianUpper: true,
        tz: row.firstDropoffTimeZone,
      }),
    filterable: true,
    filterInterface: 'date-range',
    filterProp: 'dropoffDate',
    filterValue: [],
    filterHiddenOnTopBar: true,
    hidden: true,
  },
  {
    _t_id: '0fdc3042-13dc-4c25-8ff6-51e6d1147020',
    text: 'Pickup Address',
    value: 'firstPickupAddressName',
    hidden: true,
    filterable: true,
    filterProp: 'firstPickupAddressName',
    filterBySearch: true,
    type: 'text',
  },
  {
    _t_id: 'fc7ac32c-646e-4726-9546-5f4e9a861307',
    text: 'Pickup Name',
    value: 'firstPickupStopName',
    hidden: true,
    filterable: true,
    filterProp: 'firstPickupStopName',
    filterBySearch: true,
    type: 'text',
  },
  {
    _t_id: '592a3b61-3bca-4f11-90d4-31206289ee0f',
    text: 'First Drop-off Address',
    value: 'firstDropoffAddressName',
    filterable: true,
    filterProp: 'firstDropoffAddressName',
    filterBySearch: true,
    hidden: true,
    type: 'text',
  },
  {
    _t_id: '04cc3d67-9ad9-462a-972f-dcd4202274f8',
    text: 'First Drop-off Name',
    value: 'firstDropoffStopName',
    hidden: true,
    filterable: true,
    filterProp: 'firstDropoffStopName',
    filterBySearch: true,
    type: 'text',
  },
  {
    _t_id: '56763b61-2b9e-4e7e-9b57-ca4257229959',
    text: 'Final Drop',
    value: 'finalDropoffDate',
    computedText: (row): string =>
      formatLongTime(row.finalDropoffDate, {
        showMeridianUpper: true,
        tz: row.finalDropoffTimeZone,
      }),
    filterable: true,
    filterInterface: 'date-range',
    filterProp: 'finalDropoffDate',
    filterValue: [],
    filterHiddenOnTopBar: true,
    hidden: true,
  },
  {
    _t_id: 'f4fce787-1060-4f80-8655-2098c6ffcba6',
    text: 'Final Drop-off Address',
    value: 'finalDropoffAddressName',
    filterable: true,
    filterProp: 'finalDropoffAddressName',
    filterBySearch: true,
    hidden: true,
    type: 'text',
  },
  {
    _t_id: 'e2539a59-7003-4828-b900-329379ca63de',
    text: 'Final Drop-off Name',
    value: 'finalDropoffStopName',
    filterable: true,
    filterProp: 'finalDropoffStopName',
    filterBySearch: true,
    hidden: true,
    type: 'text',
  },
  {
    _t_id: '45c184b4-f39a-44f1-98a7-6b6f22d7e0c1',
    text: 'Return',
    value: 'garageReturnTime',
    computedText: (row): string =>
      formatLongTime(row.garageReturnTime, {
        showMeridianUpper: true,
        tz: row.garageReturnTimeZone,
      }),
    filterable: true,
    filterInterface: 'date-range',
    filterProp: 'garageReturnTime',
    filterValue: [],
    filterHiddenOnTopBar: true,
    hidden: true,
  },
  {
    _t_id: '0aa0e55e-7331-4d65-b284-660af9b99a5e',
    text: 'Passenger Count',
    value: 'passengerCount',
    type: 'text',
    hidden: true,
    width: 140,
  },
  {
    _t_id: '09dd6064-f645-42cf-97ad-350ae58717e0',
    text: 'Total Drivers',
    value: 'requiredDrivers',
    type: 'text',
    hidden: true,
  },
  {
    _t_id: 'dadfca33-98be-4ff8-99ae-b312c1a62678',
    text: 'Total Vehicles',
    value: 'requiredVehicles',
    type: 'text',
    hidden: true,
  },
  {
    _t_id: '1b4ea017-7b24-4921-ac94-9e3acf232ada',
    text: 'Group',
    value: 'customerAccountGroupName',
    hidden: true,
    type: 'text',
    filterable: true,
    filterBySearch: true,
    filterProp: 'customerAccountGroupName',
    filterType: 'contains',
  },
  {
    _t_id: 'db77adcb-8a2c-4eb6-860e-1f5c151d9b95',
    text: 'Tags',
    value: 'tagsString',
    type: 'slot',
    columnSlotProps: (row: Reservation): Record<string, unknown> => ({
      tagsString: row.tagsString,
    }),
    columnSlotComponent: ListTags,
    filterProp: 'tagsString',
    filterBySearch: true,
    hidden: true,
  },
  {
    _t_id: '16b85fbc-12c8-420b-857a-ecdbf825cd3e',
    text: 'Referred By',
    value: 'referredBy',
    type: 'text',
    hidden: true,
    filterable: true,
    filterBySearch: true,
    filterProp: 'referredBy',
    filterType: 'contains',
  },
  {
    _t_id: '21353fac-e6f0-48ae-bcf5-c76948a6d32c',
    text: 'Referral ID',
    value: 'managedReferralId',
    filterProp: 'managedReferralId',
    filterBySearch: true,
    hidden: true,
    required: false,
  },
  {
    _t_id: '6ff2d488-05b8-47e0-98f3-b02ac4bce6cd',
    text: 'Event Type',
    value: 'eventTypeName',
    filterable: true,
    filterBySearch: true,
    filterProp: 'eventTypeName',
    filterType: 'contains',
    hidden: true,
    required: false,
    computedText: (row): string => row.eventTypeName || '- -',
  },
  {
    _t_id: '44a9806a-7ae6-476b-b4e3-8bee4f37dbde',
    text: 'Affiliate Contact',
    value: 'affiliateCustomerName',
    columnSlotComponent: ReservationsListAffiliateContacts,
    type: 'slot',
    hidden: true,
    filterable: true,
    filterBySearch: true,
    filterProp: 'affiliateCustomerName',
    filterType: 'contains',
  },
  {
    _t_id: '34366ad2-e27d-41b0-8bc7-9b87276106c9',
    text: 'Affiliate Company',
    value: 'affiliateCustomerAccountName',
    columnSlotComponent: ReservationsListAffiliateContacts,
    columnSlotProps: (): Record<string, unknown> => ({
      isCompany: true,
    }),
    type: 'slot',
    hidden: true,
    filterable: true,
    filterBySearch: true,
    filterProp: 'affiliateCustomerAccountName',
    filterType: 'contains',
  },
  {
    _t_id: 'aa8b6729-7f5e-4968-9897-3602d7039831',
    text: 'Farmout Amount',
    value: 'farmoutAmount',
    columnSlotComponent: ReservationsListAffiliateAmounts,
    type: 'slot',
    hidden: true,
  },
  {
    _t_id: '882f2f51-864c-458b-a040-3184a28307b4',
    text: 'Invoice Sent',
    value: 'invoiceLastSentDate',
    computedText: (row): string =>
      row.invoiceLastSentDate ? 'Sent' : 'Not Sent',
    hidden: true,
  },
  {
    _t_id: '0a6745de-d988-4ae6-8d52-2fd01f5fef4c',
    text: 'Farmout Status',
    type: 'text',
    invisible: true,
    value: 'affiliateOffers',
    filterable: true,
    filterProp: 'affiliateOffers',
    filterInterface: 'select',
    filterDropdownItems: [
      { text: 'Farmout', value: 'not null', filterType: 'isnotempty' },
      { text: 'Not Farmout', value: 'null', filterType: 'isempty' },
    ],
    filterType: 'eq',
    filterValue: -1,
    filterHiddenOnTopBar: true,
    hidden: true,
  },
  {
    _t_id: '032da335-af49-4fe4-a08c-2d8cd28df0a8',
    text: 'Taxable Sales',
    value: 'taxableSales',
    computedText: (row): string => currencyFilter(row.taxableSales),
    hidden: true,
    width: CURRENCY_COLUMN_WIDTH,
  },
  {
    _t_id: '9c2d9aea-93db-4c43-83c7-fa145172d595',
    text: 'Taxes',
    value: 'taxes',
    computedText: (row): string => currencyFilter(row.taxes),
    hidden: true,
    width: CURRENCY_COLUMN_WIDTH,
  },
  {
    _t_id: 'd378da03-e011-406c-be9a-020c9e3ad805',
    text: 'Tax Exempt Sales',
    value: 'taxExemptSales',
    computedText: (row): string => currencyFilter(row.taxExemptSales),
    hidden: true,
    width: CURRENCY_COLUMN_WIDTH,
  },
]

export const initialSavedViews: SavedView[] = [
  {
    name: 'All Reservations',
    savedViewId: -1,
    tableId: 'reservations_table_view',
    viewSettings: {
      predefined: true,
      pinned: true,
      orderIndex: 0,
      pageSize: 10,
      columns: [
        {
          _t_id: 'c6f6ae39-1ef3-4ea7-91ce-3af6240704d8',
          label: 'Assigned Status',
          hidden: false,
        },
        {
          _t_id: '2d70234a-51a6-483e-8e3d-c89d2402841f',
          label: 'Res. ID',
          hidden: false,
        },
        {
          _t_id: 'e7701736-b904-4ede-8d55-9373e7abab78',
          label: 'Res. Status',
          hidden: false,
        },
        {
          _t_id: '81d36464-ef9f-4af8-bbd1-9c13a9c92433',
          label: 'Company',
          hidden: false,
        },
        {
          _t_id: '24d55b6c-c683-4e56-b0ba-f1e0f5901589',
          label: 'Contact',
          hidden: false,
        },
        {
          _t_id: '81765aa3-dbbd-4655-9bb3-69e4ce17b138',
          label: 'Pickup',
          hidden: false,
        },
        {
          _t_id: '75b6450f-b22b-408d-8093-dd3446ddb23b',
          label: 'Total Due',
          hidden: false,
        },
        {
          _t_id: '3b6e4ec1-4786-4901-8bb8-2824b63e4165',
          label: 'Balance',
          hidden: false,
          filterValue: -1,
        },
        {
          _t_id: 'c5eceed4-f761-4b62-bcac-8e56cce0ee90',
          label: 'Vehicle',
          hidden: false,
        },
        {
          _t_id: '8435e3d9-23ae-40a8-8c48-57c66f2ae1ea',
          label: 'Driver',
          hidden: false,
        },
        {
          _t_id: '2042af9b-2f51-483f-8d5e-1756c226662f',
          label: 'Last Activity',
          hidden: false,
        },
        {
          _t_id: '1dfb99a9-a229-43cf-84f4-81d47d7d217a',
          label: 'Assigned Vehicles',
          hidden: false,
          filterValue: -1,
        },
        {
          _t_id: '4da2480b-412c-499b-a05a-0ea9c56dc8dc',
          label: 'Assigned Drivers',
          hidden: false,
          filterValue: -1,
        },
        {
          _t_id: 'b4a8bb47-5011-455b-919f-e910840e4716',
          label: 'Payment Status',
          hidden: true,
          filterValue: [],
        },
      ],
    },
  },
  {
    name: 'Upcoming',
    savedViewId: -3,
    tableId: 'reservations_table_view',
    viewSettings: {
      pinned: true,
      orderIndex: 1,
      pageSize: 10,
      currentSort: {
        id: '83353221-3cec-4490-9cee-e6188794c953',
        key: 'f53d765f-09c1-40f2-b2e0-a5378d34ffa1',
        direction: 'asc',
        prop: 'pickupDate',
      },
      columns: [
        {
          _t_id: 'c6f6ae39-1ef3-4ea7-91ce-3af6240704d8',
          hidden: false,
          filterValue: -1,
        },
        {
          _t_id: '2d70234a-51a6-483e-8e3d-c89d2402841f',
          hidden: false,
        },
        {
          _t_id: 'e7701736-b904-4ede-8d55-9373e7abab78',
          hidden: false,
        },
        {
          _t_id: '81d36464-ef9f-4af8-bbd1-9c13a9c92433',
          hidden: false,
        },
        {
          _t_id: '24d55b6c-c683-4e56-b0ba-f1e0f5901589',
          hidden: false,
        },
        {
          _t_id: '81765aa3-dbbd-4655-9bb3-69e4ce17b138',
          hidden: false,
          filterValue: ['2022-11-22', predefinedFutureDate],
        },
        {
          _t_id: '75b6450f-b22b-408d-8093-dd3446ddb23b',
          hidden: false,
        },
        {
          _t_id: '3b6e4ec1-4786-4901-8bb8-2824b63e4165',
          hidden: false,
        },
        {
          _t_id: '8435e3d9-23ae-40a8-8c48-57c66f2ae1ea',
          hidden: false,
        },
        {
          _t_id: 'c5eceed4-f761-4b62-bcac-8e56cce0ee90',
          hidden: false,
        },
        {
          _t_id: '2042af9b-2f51-483f-8d5e-1756c226662f',
          hidden: false,
        },
        {
          _t_id: '1dfb99a9-a229-43cf-84f4-81d47d7d217a',
          hidden: false,
          filterValue: -1,
        },
        {
          _t_id: '4da2480b-412c-499b-a05a-0ea9c56dc8dc',
          hidden: false,
          filterValue: -1,
        },
        {
          _t_id: 'b4a8bb47-5011-455b-919f-e910840e4716',
          hidden: true,
          filterValue: [],
        },
        {
          _t_id: '0d3e73cc-25a0-4514-a754-3dccaa709e2f',
          hidden: true,
        },
        {
          _t_id: 'd14b91cb-3bb3-4aa2-8d19-b07739c19a92',
          hidden: true,
        },
        {
          _t_id: '7fc5f8d6-518d-4719-9d69-dd522260a6a8',
          hidden: true,
          filterValue: [],
        },
        {
          _t_id: '63252c8d-ce34-4aba-bdba-c956dbd69c46',
          hidden: true,
        },
        {
          _t_id: 'afe96b24-5112-4f37-b12d-c0f76280a18b',
          hidden: true,
          filterValue: [],
        },
        {
          _t_id: '449766e5-fb0a-478e-b0c8-c4ee5f5cc137',
          hidden: true,
        },
        {
          _t_id: 'a0da93dc-9480-4133-abc8-960961652791',
          hidden: true,
        },
        {
          _t_id: '596a864c-2608-4232-a125-e8233e2c5fad',
          hidden: true,
        },
        {
          _t_id: '40ab59df-9105-47cd-9000-d0776de3ae88',
          hidden: true,
          filterValue: [],
        },
        {
          _t_id: '9c5a5a08-6e0f-4487-b459-c1bed2b82ed6',
          hidden: true,
          filterValue: -1,
        },
        {
          _t_id: '0844dbdb-c99a-48b4-af1b-7c1bbd05369d',
          hidden: true,
        },
        {
          _t_id: '550eceb4-ebca-4322-a749-a324ca694564',
          hidden: true,
          filterValue: [],
        },
        {
          _t_id: '0fdc3042-13dc-4c25-8ff6-51e6d1147020',
          hidden: true,
        },
        {
          _t_id: '592a3b61-3bca-4f11-90d4-31206289ee0f',
          hidden: true,
        },
        {
          _t_id: '56763b61-2b9e-4e7e-9b57-ca4257229959',
          hidden: true,
          filterValue: [],
        },
        {
          _t_id: '09dd6064-f645-42cf-97ad-350ae58717e0',
          hidden: true,
        },
        {
          _t_id: 'dadfca33-98be-4ff8-99ae-b312c1a62678',
          hidden: true,
        },
        {
          _t_id: '1b4ea017-7b24-4921-ac94-9e3acf232ada',
          hidden: true,
        },
        {
          _t_id: 'db77adcb-8a2c-4eb6-860e-1f5c151d9b95',
          hidden: true,
        },
        {
          _t_id: '16b85fbc-12c8-420b-857a-ecdbf825cd3e',
          hidden: true,
        },
        {
          _t_id: '21353fac-e6f0-48ae-bcf5-c76948a6d32c',
          hidden: true,
        },
      ],
    },
  },
  {
    name: 'Unpaid',
    savedViewId: -2,
    tableId: 'reservations_table_view',
    viewSettings: {
      predefined: true,
      pageSize: 10,
      pinned: true,
      orderIndex: 2,
      columns: [
        {
          _t_id: 'c6f6ae39-1ef3-4ea7-91ce-3af6240704d8',
          label: 'Assigned Status',
          hidden: false,
        },
        {
          _t_id: '2d70234a-51a6-483e-8e3d-c89d2402841f',
          label: 'Res. ID',
          hidden: false,
        },
        {
          _t_id: 'e7701736-b904-4ede-8d55-9373e7abab78',
          label: 'Res. Status',
          hidden: false,
        },
        {
          _t_id: '81d36464-ef9f-4af8-bbd1-9c13a9c92433',
          label: 'Company',
          hidden: false,
        },
        {
          _t_id: '24d55b6c-c683-4e56-b0ba-f1e0f5901589',
          label: 'Contact',
          hidden: false,
        },
        {
          _t_id: '81765aa3-dbbd-4655-9bb3-69e4ce17b138',
          label: 'Pickup',
          hidden: false,
        },
        {
          _t_id: '75b6450f-b22b-408d-8093-dd3446ddb23b',
          label: 'Total Due',
          hidden: false,
        },
        {
          _t_id: '3b6e4ec1-4786-4901-8bb8-2824b63e4165',
          label: 'Balance',
          hidden: false,
        },
        {
          _t_id: '8435e3d9-23ae-40a8-8c48-57c66f2ae1ea',
          label: 'Driver',
          hidden: false,
        },
        {
          _t_id: 'c5eceed4-f761-4b62-bcac-8e56cce0ee90',
          label: 'Vehicle',
          hidden: false,
        },
        {
          _t_id: '2042af9b-2f51-483f-8d5e-1756c226662f',
          label: 'Last Activity',
          hidden: false,
        },
        {
          _t_id: '1dfb99a9-a229-43cf-84f4-81d47d7d217a',
          label: 'Assigned Vehicles',
          hidden: false,
          filterValue: -1,
        },
        {
          _t_id: '4da2480b-412c-499b-a05a-0ea9c56dc8dc',
          label: 'Assigned Drivers',
          hidden: false,
          filterValue: -1,
        },
        {
          _t_id: 'b4a8bb47-5011-455b-919f-e910840e4716',
          label: 'Payment Status',
          hidden: true,
          filterValue: ['not_paid', 'partially_paid'],
          filterType: 'eq',
        },
      ],
    },
  },
  {
    name: 'Next 3 Days',
    savedViewId: -4,
    tableId: 'reservations_table_view',
    viewSettings: {
      pinned: true,
      orderIndex: 1,
      pageSize: 25,
      currentSort: {
        id: '83353221-3cec-4490-9cee-e6188794c953',
        key: 'f53d765f-09c1-40f2-b2e0-a5378d34ffa1',
        direction: 'asc',
        prop: 'pickupDate',
      },
      columns: [
        {
          _t_id: 'c6f6ae39-1ef3-4ea7-91ce-3af6240704d8',
          hidden: false,
          filterValue: -1,
        },
        {
          _t_id: '2d70234a-51a6-483e-8e3d-c89d2402841f',
          hidden: false,
        },
        {
          _t_id: 'e7701736-b904-4ede-8d55-9373e7abab78',
          hidden: false,
        },
        {
          _t_id: '81d36464-ef9f-4af8-bbd1-9c13a9c92433',
          hidden: false,
        },
        {
          _t_id: '24d55b6c-c683-4e56-b0ba-f1e0f5901589',
          hidden: false,
        },
        {
          _t_id: '81765aa3-dbbd-4655-9bb3-69e4ce17b138',
          hidden: false,
          filterValue: [
            dayjs().format('YYYY-MM-DD'),
            dayjs().add(2, 'day').format('YYYY-MM-DD'),
          ],
        },
        {
          _t_id: '75b6450f-b22b-408d-8093-dd3446ddb23b',
          hidden: false,
        },
        {
          _t_id: '3b6e4ec1-4786-4901-8bb8-2824b63e4165',
          hidden: false,
        },
        {
          _t_id: '8435e3d9-23ae-40a8-8c48-57c66f2ae1ea',
          hidden: false,
        },
        {
          _t_id: 'c5eceed4-f761-4b62-bcac-8e56cce0ee90',
          hidden: false,
        },
        {
          _t_id: '2042af9b-2f51-483f-8d5e-1756c226662f',
          hidden: false,
        },
        {
          _t_id: '1dfb99a9-a229-43cf-84f4-81d47d7d217a',
          hidden: false,
          filterValue: -1,
        },
        {
          _t_id: '4da2480b-412c-499b-a05a-0ea9c56dc8dc',
          hidden: false,
          filterValue: -1,
        },
        {
          _t_id: 'b4a8bb47-5011-455b-919f-e910840e4716',
          hidden: true,
          filterValue: [],
        },
        {
          _t_id: '0d3e73cc-25a0-4514-a754-3dccaa709e2f',
          hidden: true,
        },
        {
          _t_id: 'd14b91cb-3bb3-4aa2-8d19-b07739c19a92',
          hidden: true,
        },
        {
          _t_id: '7fc5f8d6-518d-4719-9d69-dd522260a6a8',
          hidden: true,
          filterValue: [],
        },
        {
          _t_id: '63252c8d-ce34-4aba-bdba-c956dbd69c46',
          hidden: true,
        },
        {
          _t_id: 'afe96b24-5112-4f37-b12d-c0f76280a18b',
          hidden: true,
          filterValue: [],
        },
        {
          _t_id: '449766e5-fb0a-478e-b0c8-c4ee5f5cc137',
          hidden: true,
        },
        {
          _t_id: 'a0da93dc-9480-4133-abc8-960961652791',
          hidden: true,
        },
        {
          _t_id: '596a864c-2608-4232-a125-e8233e2c5fad',
          hidden: true,
        },
        {
          _t_id: '40ab59df-9105-47cd-9000-d0776de3ae88',
          hidden: true,
          filterValue: [],
        },
        {
          _t_id: '9c5a5a08-6e0f-4487-b459-c1bed2b82ed6',
          hidden: true,
          filterValue: -1,
        },
        {
          _t_id: '0844dbdb-c99a-48b4-af1b-7c1bbd05369d',
          hidden: true,
        },
        {
          _t_id: '550eceb4-ebca-4322-a749-a324ca694564',
          hidden: true,
          filterValue: [],
        },
        {
          _t_id: '0fdc3042-13dc-4c25-8ff6-51e6d1147020',
          hidden: true,
        },
        {
          _t_id: '592a3b61-3bca-4f11-90d4-31206289ee0f',
          hidden: true,
        },
        {
          _t_id: '56763b61-2b9e-4e7e-9b57-ca4257229959',
          hidden: true,
          filterValue: [],
        },
        {
          _t_id: '09dd6064-f645-42cf-97ad-350ae58717e0',
          hidden: true,
        },
        {
          _t_id: 'dadfca33-98be-4ff8-99ae-b312c1a62678',
          hidden: true,
        },
        {
          _t_id: '1b4ea017-7b24-4921-ac94-9e3acf232ada',
          hidden: true,
        },
        {
          _t_id: 'db77adcb-8a2c-4eb6-860e-1f5c151d9b95',
          hidden: true,
        },
        {
          _t_id: '16b85fbc-12c8-420b-857a-ecdbf825cd3e',
          hidden: true,
        },
        {
          _t_id: '21353fac-e6f0-48ae-bcf5-c76948a6d32c',
          hidden: true,
        },
      ],
    },
  },
]
