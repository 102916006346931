
import { Reservation } from '@/models/dto/Reservation'
import { Vue, Component, Prop } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import { AffiliateOffer } from '@/models/dto/Affiliate'
import ContactSidebarDetail from './ContactSidebarDetail.vue'
import CompanySidebarDetail from './CompanySidebarDetail.vue'

@Component({})
export default class ReservationsListAffiliateContacts extends Vue {
  @Prop({
    required: true,
  })
  row: Reservation

  @Prop({ required: false, default: false }) isCompany: boolean

  EventBus = EventBus
  offers: AffiliateOffer[] = []

  get affiliateOffers(): AffiliateOffer[] {
    return this.row.affiliateOffers.filter((offer) => offer.isActive)
  }

  get canViewContacts(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.CONTACTS)
  }

  formatName(offer: AffiliateOffer): string {
    return this.isCompany
      ? offer?.customerAccount?.name || ''
      : `${offer?.customer?.firstName || ''} ${offer?.customer?.lastName || ''}`
  }

  openContactDetail(offer: AffiliateOffer): void {
    if (this.isCompany) {
      sidebar.popAllAndPush({
        component: CompanySidebarDetail,
        props: {
          customerAccountId: offer.customerAccountId,
        },
      })
    } else {
      sidebar.popAllAndPush({
        component: ContactSidebarDetail,
        props: {
          userId: offer.customerId,
          simple: true,
          contactType: '',
        },
      })
    }
  }

  handleRefresh(): void {
    sidebar.pop()
    EventBus.$emit('refresh-tableview')
  }
}
