
import { Reservation } from '@/models/dto/Reservation'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/string'
import dayjs from 'dayjs'

@Component({
  components: {},
})
export default class ReservationsListTotalDue extends Vue {
  currencyFilter = currencyFilter
  @Prop({
    required: true,
  })
  row: Reservation

  get paymentIsOverdue(): boolean {
    if (!this.row.dueDate) {
      return false
    }

    if (!this.row.balance) {
      return false
    }
    const dueDate = dayjs(this.row.dueDate)
    const now = dayjs()
    return now.isAfter(dueDate, 'day')
  }
}
