
import { Reservation } from '@/models/dto/Reservation'
import { Vue, Component, Prop } from 'vue-property-decorator'
import AssignedBus from '@/assets/images/AssignedBus.vue'
import UnassignedBus from '@/assets/images/UnassignedBus.vue'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: { AssignedBus, UnassignedBus },
})
export default class ReservationsListAssignedIcon extends Vue {
  @Prop({
    required: true,
  })
  row: Reservation

  get fullyAssigned(): boolean {
    return (
      this.row.assignedDriverPercentage === 100 &&
      this.row.assignedVehiclePercentage === 100
    )
  }

  openAssignments(): void {
    EventBus.$emit('reservations:open-assignments', this.row)
  }
}
